var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "password"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-6"
  }, [_vm._v("Update Password")]), _c('div', {
    staticClass: "bg-white rounded-md px-6 py-10"
  }, [_c('p', {
    staticClass: "text-center mb-6"
  }, [_vm._v("Masukan informasi yang dibutuhkan untuk melakukan pengubahan password dari user terkait.")]), _c('div', {
    staticClass: "flex flex-col items-center gap-6 max-w-sm mx-auto mb-10"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('TextField', {
    attrs: {
      "label": "Username (Email)",
      "placeholder": "Masukan email",
      "error": _vm.errors['username']
    },
    model: {
      value: _vm.payload.username,
      callback: function ($$v) {
        _vm.$set(_vm.payload, "username", $$v);
      },
      expression: "payload.username"
    }
  })], 1), _c('div', {
    staticClass: "w-full"
  }, [_c('TextField', {
    attrs: {
      "label": "Password",
      "placeholder": "Masukan password",
      "error": _vm.errors['password']
    },
    model: {
      value: _vm.payload.password,
      callback: function ($$v) {
        _vm.$set(_vm.payload, "password", $$v);
      },
      expression: "payload.password"
    }
  })], 1), _c('div', {
    staticClass: "w-full"
  }, [_c('TextField', {
    attrs: {
      "label": "Konfirmasi Password",
      "placeholder": "Masukan konfirmasi password",
      "error": _vm.errors['confirm_password']
    },
    model: {
      value: _vm.payload.confirm_password,
      callback: function ($$v) {
        _vm.$set(_vm.payload, "confirm_password", $$v);
      },
      expression: "payload.confirm_password"
    }
  })], 1)]), _c('div', {
    staticClass: "flex justify-center items-center flex-col"
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.generalError ? _c('div', {
    staticClass: "text-system-error mb-6"
  }, [_vm._v(_vm._s(_vm.generalError))]) : _vm._e()]), _c('Button', {
    attrs: {
      "buttonText": "Update Password",
      "type": "primary"
    },
    on: {
      "action": function ($event) {
        return _vm.doUpdate();
      }
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }